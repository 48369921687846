/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'

const notificationService = {
	getNotification(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notifications/${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getNotifications() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notifications`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default notificationService
