<template>
	<v-card class="notification">
		<v-card-title>
			<v-icon class="mr-2">mdi-bell-outline</v-icon>
			공지사항
		</v-card-title>
		<v-list v-if="notices.length > 0">
			<v-list-item
				v-for="(notice, index) in notices"
				:key="'notice' + index"
				@click="openMyPageNotificationDialog(notice)"
			>
				<v-list-item-content>
					<v-list-item-title class="d-flex">
						{{ notice.title }}
						<v-spacer />
						<span class="d-none d-sm-block">
							{{ notice.createdAt | dateFormat }}
						</span>
					</v-list-item-title>
					<v-list-item-subtitle class="d-block d-sm-none">
						{{ notice.createdAt | dateFormat }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-list v-else>
			<v-list-item-content>
				<v-list-item-title class="d-flex justify-center">
					공지사항이 없습니다
				</v-list-item-title>
			</v-list-item-content>
		</v-list>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { warningSwal } from '@/plugins/swalMixin'

import NoticeService from '@/services/NoticeService'

export default {
	components: {},
	setup(_, { emit }) {
		const notices = ref([])

		const getNotices = async () => {
			try {
				const data = await NoticeService.getNotices()
				notices.value = data.filter(obj => obj.visible === true)
			} catch (e) {
				warningSwal('공지사항을 가져오는데 문제가 발생했습니다.')
			}
		}
		getNotices()

		const openMyPageNotificationDialog = notice => {
			emit('notice', notice)
			emit('is-open-my-page-notice-dialog', true)
		}

		return {
			notices,

			openMyPageNotificationDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.notification,
.notice {
	.v-list-item {
		border-bottom: 1px solid #e0e0e0;

		&:last-child {
			border-bottom: none;
		}
	}
}
</style>
