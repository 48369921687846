<template>
	<v-dialog v-model="isOpenMyPageNotificationDialog" persistent max-width="700">
		<v-card>
			<v-card-title :class="{ 'px-3': isSmAndDown }">
				<span class="text-base">{{ notification.title }}</span>
				<v-spacer />
				<v-btn
					icon
					@click="$emit('update:is-open-my-page-notification-dialog', false)"
				>
					<v-icon size="22">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-3" />
			<v-card-text :class="{ 'px-3': isSmAndDown }">
				<span v-html="notification.content"></span>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

export default {
	props: {
		notification: {
			type: Object,
		},
		isOpenMyPageNotificationDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup() {
		const $vuetify = getVuetify()

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		return {
			isSmAndDown,
		}
	},
}
</script>
<style lang="scss" scoped>
.notification,
.notice {
	.v-list-item {
		border-bottom: 1px solid #e0e0e0;

		&:last-child {
			border-bottom: none;
		}
	}
}
</style>
