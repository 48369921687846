<template>
	<div>
		<div>
			<v-row>
				<v-col cols="12" md="7" sm="12">
					<my-page-notification-notice
						@is-open-my-page-notice-dialog="isOpenMyPageNoticeDialog = $event"
						@notice="selectedNotice = $event"
					/>
				</v-col>

				<v-col cols="12" md="5" sm="12" :class="{ 'pt-0': !isMdAndUp }">
					<my-page-notification-notifications
						@is-open-my-page-notification-dialog="
							isOpenMyPageNotificationDialog = $event
						"
						@notification="selectedNotification = $event"
					/>
				</v-col>
			</v-row>
		</div>
		<my-page-notification-dialog
			:notification="selectedNotification"
			:is-open-my-page-notification-dialog.sync="isOpenMyPageNotificationDialog"
		/>
		<my-page-notice-dialog
			:selected-notice="selectedNotice"
			:is-open-my-page-notice-dialog.sync="isOpenMyPageNoticeDialog"
		/>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import MyPageNoticeDialog from './MyPageNoticeDialog.vue'
import MyPageNotificationDialog from './MyPageNotificationDialog.vue'

import MyPageNotificationNotifications from './components/MyPageNotificationNotifications.vue'
import MyPageNotificationNotice from './components/MyPageNotificationNotice.vue'

export default {
	components: {
		MyPageNoticeDialog,
		MyPageNotificationDialog,
		MyPageNotificationNotifications,
		MyPageNotificationNotice,
	},
	setup() {
		const $vuetify = getVuetify()
		const selectedNotice = ref({})
		const selectedNotification = ref({})
		const isOpenMyPageNoticeDialog = ref(false)
		const isOpenMyPageNotificationDialog = ref(false)

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		return {
			isMdAndUp,
			selectedNotice,
			selectedNotification,
			isOpenMyPageNoticeDialog,
			isOpenMyPageNotificationDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.notification,
.notice {
	.v-list-item {
		border-bottom: 1px solid #e0e0e0;

		&:last-child {
			border-bottom: none;
		}
	}
}
</style>
