<template>
	<v-card class="notice">
		<v-card-title>
			<v-icon class="mr-2">mdi-bell-outline</v-icon>
			알림
		</v-card-title>
		<v-list v-if="notifications.length > 0">
			<v-list-item
				v-for="(notification, index) in notifications"
				:key="'notification' + index"
				@click="openMyPageNotificationDialog(notification)"
			>
				<v-list-item-content>
					<v-list-item-title class="d-flex">
						{{ notification.title }}
						<v-spacer />
						<span class="d-none d-sm-block">
							{{ notification.createdAt | dateFormat }}
						</span>
					</v-list-item-title>
					<v-list-item-subtitle class="d-block d-sm-none">
						{{ notification.createdAt | dateFormat }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-list v-else>
			<v-list-item-content>
				<v-list-item-title class="d-flex justify-center">
					알림이 없습니다
				</v-list-item-title>
			</v-list-item-content>
		</v-list>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { warningSwal } from '@/plugins/swalMixin'

import NotificationService from '@/services/NotificationService'

export default {
	components: {},
	setup(_, { emit }) {
		const notifications = ref([])

		const getNotifications = async () => {
			try {
				notifications.value = await NotificationService.getNotifications()
			} catch (e) {
				warningSwal('알림을 가져오는데 문제가 발생했습니다.')
			}
		}
		getNotifications()

		const openMyPageNotificationDialog = notification => {
			emit('notification', notification)
			emit('is-open-my-page-notification-dialog', true)
		}

		return {
			notifications,

			openMyPageNotificationDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.notification,
.notice {
	min-height: 250px;

	.v-list-item {
		border-bottom: 1px solid #e0e0e0;

		&:last-child {
			border-bottom: none;
		}
	}
}
</style>
